import React, {useEffect, useState} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import Select from 'react-select';
import {observer} from "mobx-react-lite";
import AccountStore from "../../../stores/AccountStore";
import TransactionStore from "../../../stores/TransactionStore";

const CreateTransactionModal = observer(({}) => {
    const [description, setDescription] = useState('');
    const [amount, setAmount] = useState(0);
    const [from_account, setFromAccount] = useState(0);
    const [to_account, setToAccount] = useState(0);
    const [transaction_type, setTransactionType] = useState(0);
    const [created_at, setCreatedAt] = useState(new Date().toISOString().split('T')[0]);

    const accountOptions = AccountStore.accounts.map((a) => ({
        value: a.id,
        label: `${a.number}| ${a.name}`
    }));

    const transactionTypeOptions = TransactionStore.transactionTypes.map((t) => ({
        value: t.id,
        label: t.name
    } ));

    if (TransactionStore.transactionTypes.length === 0) {
        // Modal with error message
        return (
            <Modal show={TransactionStore.showModals.create} onHide={() => TransactionStore.setShowModal('create', false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Transaction</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>There are no transaction types. Please create one first.</p>
                </Modal.Body>
            </Modal>
        );
    } else if (AccountStore.accounts.length <= 1) {
        // Modal with error message
        return (
            <Modal show={TransactionStore.showModals.create} onHide={() => TransactionStore.setShowModal('create', false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Create Transaction</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>There are not enough accounts. Please create two accounts first.</p>
                </Modal.Body>
            </Modal>
        );
    }


    const onSubmit = (e) => {
        e.preventDefault();
        const newTransaction = {
            description,
            amount,
            from_account,
            to_account,
            transaction_type,
            created_at
        }
        TransactionStore.handleCreate(newTransaction, TransactionStore.types.TRANSACTION);
    };

    return (
        <Modal show={TransactionStore.showModals.create} onHide={() => TransactionStore.setShowModal('create', false)}>
            <Modal.Header closeButton>
                <Modal.Title>Create Transaction</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmit}>
                    <Form.Group controlId="from_account">
                        <Form.Label>From Account</Form.Label>
                        <Select
                            value={
                                accountOptions.find((a) => a.value === from_account) ||
                                accountOptions[0]
                            }
                            onChange={(e) => setFromAccount(Number(e.value))}
                            options={accountOptions}
                            isSearchable={true}
                        />
                    </Form.Group>
                    <Form.Group controlId="to_account">
                        <Form.Label>To Account</Form.Label>
                        <Select
                            value={
                                accountOptions.find((a) => a.value === to_account) ||
                                accountOptions[1]
                            }
                            onChange={(e) => setToAccount(Number(e.value))}
                            options={accountOptions}
                            isSearchable={true}
                        />
                    </Form.Group>
                    <Form.Group controlId="amount">
                        <Form.Label>Amount</Form.Label>
                        <Form.Control type="number" value={amount} onChange={(e) => setAmount(e.target.value)}/>
                    </Form.Group>
                    <Form.Group controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control type="text" value={description} onChange={(e) => setDescription(e.target.value)}/>
                    </Form.Group>
                    <Form.Group controlId="type">
                        <Form.Label>Type</Form.Label>
                        <Select
                            value={
                                transactionTypeOptions.find((t) => t.value === transaction_type) ||
                                transactionTypeOptions[0]
                            }
                            onChange={(e) => setTransactionType(Number(e.value))}
                            options={transactionTypeOptions}
                            isSearchable={true}
                        />
                    </Form.Group>
                    <Form.Group controlId="created_at">
                        <Form.Label>Date</Form.Label>
                        <Form.Control type="date" value={created_at} onChange={(e) => setCreatedAt(e.target.value)}/>
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Create
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
});

export default CreateTransactionModal;
